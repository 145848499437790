<template>
  <div class="setpwd">
    <div class="pwd_title">
      <van-nav-bar
        title="找回密码"
        left-arrow
        @click-left="onClickLeft"
      />
    </div>
    <div class="from_list">
      <van-form @submit="onSubmit">
        <van-field
          v-model="mobile"
          ref="mobile"
          center
          clearable
          label="手机号"
          placeholder="输入账户绑定手机号"
          :rules="[
            { required: true, message: '输入账户绑定手机号' },
            { validator, message: '请输入正确的手机号' }
          ]"
        >
          <template #button>
            <van-button
              v-show="!showTxt"
              @click="sendSms"
              size="mini"
              native-type="button"
              type="danger"
            >发送验证码</van-button>
            <van-count-down
              :time="time"
              auto-start
              format="ss"
              v-show="showTxt"
              @finish="finish"
              ref="daojishi"
            >
              <template #default="timeData">
                <span class="seconds">{{ timeData.seconds }}秒后重发</span>
              </template>
            </van-count-down>
          </template>
        </van-field>
        <van-field
          v-model="sms"
          name="sms"
          label="验证码"
          placeholder="输入短信验证码"
          :rules="[{ required: true, message: '请输入短信验证码' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="新密码"
          placeholder="输入新密码"
          :rules="[{ required: true, message: '请输入新密码' }]"
        />
        <van-field
          v-model="password2"
          type="password"
          name="password2"
          label="重复新密码"
          placeholder="再次输入新密码"
          :rules="[{ required: true, message: '请输入正确内容' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="danger" :loading="btnlod" :native-type="!btnlod ? 'submit' : 'button'">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Setpwd",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      mobile: "", // 手机号
      sms: "", // 验证码
      password: "", // 新密码
      password2: "", // 重复新密码
      time: 30 * 1000, // 倒计时
      showTxt: false, // 是否显示倒计时
      btnlod: false, // 防止重复提交
    }
  },
  methods: {
    validator(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    // 发送验证码
    sendSms() {
      let that = null;
      that = this;
      if (!that.mobile) {
        that.$toast("请输入手机号");
        that.$refs.mobile.focus();
        return false;
      }
      if (!that.validator(that.mobile)) {
        that.$toast("请输入正确的手机号");
        return false;
      }
      
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "team/user/do_sms",
          that.$qs.stringify({
            mobile: that.mobile
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.$refs.daojishi.reset();
            that.showTxt = true;
          } else {
            that.$toast(res.data.msg ? res.data.msg : "发送失败"); 
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 倒计时结束
    finish() {
      this.btnDisabled = false;
      this.showTxt = false;
    },
    onSubmit() {
      console.log("submit");
      let that = null;
      that = this;

      that.btnlod = true;
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "team/user/set_pwd",
          that.$qs.stringify({
            mobile: that.mobile,
            sms: that.sms,
            pwd: that.password,
            pwd1: that.password2
          })
        )
        .then(res => {
          console.log(res);
          that.btnlod = false;
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "修改成功");
            setTimeout(() => {
              that.onClickLeft();
            }, 1000);
          }else {
            that.$toast(res.data.msg ? res.data.msg : "修改失败"); 
          }
        })
        .catch(err => {
          that.btnlod = false;
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    }
  },
}
</script>
<style lang="less">
.setpwd {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .pwd_title {
    overflow: hidden;
    margin: 0 0 60px;
  }
  .from_list {
    overflow: hidden;
    padding: 10px 16px;
    .van-form {
      .van-cell {
        overflow: hidden;
        .van-field__label {
          width: 75px;
          margin-right: 5px;
        }
        .van-cell__value {
          flex: 1;
          overflow: hidden;
          .seconds {
            padding: 5px 8px;
            color: #fff;
            background: #999;
            border-radius: 2px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>